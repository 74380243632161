
import {
  AnalyticFilter,
  AnalyticsTypes,
} from "@/store/modules/analytics/analytics.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Analytics = namespace("Analytics");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    TicketsByAssignees: () =>
      import("@/components/analytics/user/TicketsByAssignees.vue"),
    TicketsByCategories: () =>
      import("@/components/analytics/user/TicketsByCategories.vue"),
    TicketsByProduct: () =>
      import("@/components/analytics/user/TicketsByProduct.vue"),
    TicketsByPriority: () =>
      import("@/components/analytics/user/TicketsByPriority.vue"),
  },
})
export default class UserAnalyticsView extends Vue {
  public start = "";
  public end = "";

  @Ref("ticketByCategories") public categoriesRef!: AnalyticComponent;
  @Ref("ticketByAssignees") public assigneesRef!: AnalyticComponent;
  @Ref("ticketByProduct") public productsRef!: AnalyticComponent;
  @Ref("ticketByPriority") public priorityRef!: AnalyticComponent;

  @Analytics.State(AnalyticsTypes.ANALYTICS_FILTER)
  public analyticsFilter!: AnalyticFilter;

  @Analytics.Action(AnalyticsTypes.LOAD_ASSIGNEE_ANALYTICS)
  public loadAssigneeStats!: (filter: AnalyticFilter) => void;

  filterAnalytics(forced = false): void {
    this.categoriesRef.filterChanged({ forced });
    this.assigneesRef.filterChanged({ forced });
    this.productsRef.filterChanged({ forced });
    this.priorityRef.filterChanged({ forced });
  }

  mounted(): void {
    this.end = this.analyticsFilter.end || "";
    this.start = this.analyticsFilter.start || "";
  }
}

export type AnalyticComponent = {
  filterChanged: (value: { forced?: boolean }) => void;
};
